import React, {useRef, useState, useEffect, useContext} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {AppState} from "lib/context/AppProvider";
import {sortBy, filter} from "lodash";
import PropTypes from "prop-types";
import {useMediaQuery} from "react-responsive";
import {useUtilities, toUtc, calcTimeUntilCurrentTime} from "lib/helpers/globeview/useUtilities";
import {GlobeState} from "lib/context/GlobeProvider";
// import {useInView} from "react-intersection-observer";
import {Checkbox} from "@mui/material";
import {grey} from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";

const inPassStyle = {
    backgroundColor: "#00ff113d",
};

const prevPassStyle = {
    backgroundColor: "#7d0000b3",
};

const getGSNameSat = (sat, gsId) => {
    if (!sat.groundstations || !(gsId in sat.groundstations)) {
        return gsId;
    }
    return sat.groundstations[gsId].gs_name;
};

const getGSBandSat = (sat, gsId) => {
    if (!sat.groundstations || !(gsId in sat.groundstations)) {
        return "N/A";
    }
    const gs = sat.groundstations[gsId];

    return gs && gs.telecoms?.length > 0 ? gs.telecoms[0].telecom_name : "N/A";
};

export function processOverPasses(overPasses) {
    let orderedPasses = sortBy(overPasses, (o) => {
        return toUtc(o.aos, true);
    });// .slice(0, 10);

    const gsNames = [...new Set(orderedPasses.map((p) => p.gs_id))];
    orderedPasses = orderedPasses.filter((p) => {
        return gsNames.includes(p.gs_id);
    });

    return orderedPasses;
}

export const getNextPass = (sat, currentTime) => {
    if (!sat.overpasses) {
        return null;
    }

    // assumes ordered
    // const orderedPasses = processOverPasses(sat.overpasses);

    // const future = filter(orderedPasses, (o) => {
    //     return toUtc(o.aos, true) > currentTime;
    // });
    // return future?.length > 0 ? future[0] : null;
    const p = sat.overpasses.find((o) => toUtc(o.aos, true) > currentTime);
    if (p) {
        p.timeUntil = calcTimeUntilCurrentTime(toUtc(p.aos), currentTime);
        p.gs_name = getGSNameSat(sat, p.gs_id);
        p.band = getGSBandSat(sat, p.gs_id);
        return p;
    }
    return null;
};

export const getCurrentPass = (sat, currentTime) => {
    if (!sat.overpasses) {
        return null;
    }

    // assumes ordered
    const p = sat.overpasses.find((o) => {
        const aos = toUtc(o.aos, true);
        const los = toUtc(o.los, true);
        // BW3 ignores 60-second contacts since those are made in the backend when commands are issued immediately from PIGI
        return currentTime > aos && currentTime < los && (los - aos !== 60000 || o.source === "ast-quindar");
    });

    if (p) {
        p.timeUntilLos = calcTimeUntilCurrentTime(toUtc(p.los), currentTime);
        p.gs_name = getGSNameSat(sat, p.gs_id);
        p.band = getGSBandSat(sat, p.gs_id);
        return p;
    }
    return null;
};


export const getNextPassAll = (satellites, currentTime) => {
    let firstPass = null;
    for (const sat of satellites) {
        if (sat.overpasses) {
            const nextPass = getNextPass(sat, currentTime);
            if (nextPass && (!firstPass || toUtc(nextPass.aos, true) < toUtc(firstPass.aos, true))) {
                firstPass = nextPass;
            }
        }
    }
    return firstPass;
};
export const getCurrentPassesAll = (satellites, currentTime) => {
    const passes = [];
    for (const sat of satellites) {
        if (sat.overpasses) {
            const currentPass = getCurrentPass(sat, currentTime);
            if (currentPass) {
                passes.push(currentPass);
            }
        }
    }
    return passes;
};

const GroundStationTable = React.memo(() => {
    const {store} = useContext(AppState);
    const isMobile = useMediaQuery({query: "(max-width: 760px)"});
    const {
        satellites,
        selectedSatIndex,
        setNextPass,
        currentTime,
        previousPass,
        currentPass,
        setCurrentPass,
        setPreviousPass,
        audioAlert,
    } = store;

    const {globe} = useContext(GlobeState);
    const {
        hiddenSatellites,
        showAllContacts,
        setShowAllContacts,
    } = globe;

    const onShowAllContactsCheck = (e) => {
        setShowAllContacts(e.target.checked);
    };

    const [hasLoaded, setHasLoaded] = useState(false);
    const [upcomingPasses, setUpcomingPasses] = useState([]);
    const groundstations = useRef(null);
    const [lastAosCallout, setLastAosCallout] = useState(null);
    const {calcTimeUntil, calcEclipse, speak} = useUtilities(currentTime);

    // const overPasses = satellites.map((sat) => sat.overpasses);

    // const {ref, inView} = useInView({
    //     triggerOnce: false,
    //     threshold: 0.1,
    // });
    useEffect(() => {
        if (!satellites || satellites.length === 0
            || !satellites[selectedSatIndex].groundstations || !satellites[selectedSatIndex].overpasses) { // !overPasses[selectedSatIndex]) {
            setUpcomingPasses([]);
            setPreviousPass([]);
            setCurrentPass([]);
            setNextPass([]);
            return;
        }

        setHasLoaded(true);

        const allOverpasses = [];
        const gs = {};

        for (let i = 0; i < satellites.length; i++) {
            if (i === selectedSatIndex || (showAllContacts && !hiddenSatellites.includes(i))) {
                allOverpasses.push(...satellites[i].overpasses.map((row) => ({satIndex: i, ...row})));
                for (const gsid of Object.keys(satellites[i].groundstations)) {
                    gs[gsid] = satellites[i].groundstations[gsid];
                }
            }
        }
        groundstations.current = gs;

        // const sat = satellites[selectedSatIndex];
        // const allOverpasses = sat.overpasses.map((row) => ({satIndex: selectedSatIndex, ...row}));
        // groundstations.current = sat.groundstations;

        const orderedPasses = processOverPasses(allOverpasses);

        const passes = filterPasses(orderedPasses, currentTime);

        if (audioAlert && passes.upcomingAOS.length > 0
            && (!lastAosCallout || new Date().getTime() - lastAosCallout.getTime() > 60000)
            && speechSynthesis.getVoices().length > 0) {
            setLastAosCallout(new Date());
            speak("One minute to A O S");
        }

        setPreviousPass(passes.previous);
        setCurrentPass(passes.current);
        setUpcomingPasses(passes.future);
        setNextPass(passes.future[0]);
    }, [currentTime, selectedSatIndex, satellites, showAllContacts]);

    function filterPasses(orderedPasses, currentTime) {
        const previous = filter(orderedPasses, (o) => {
            const isPrevious = currentTime > toUtc(o.los, true) && currentTime - toUtc(o.los, true) < 120000;
            return isPrevious;
        }).map((p) => {
            p.timeUntil = calcTimeUntil(toUtc(p.aos));
            p.gs_name = getGSName(p.gs_id);
            p.band = getGSBand(p.gs_id);
            return p;
        }).slice(0, 3);

        const future = filter(orderedPasses, (o) => {
            return toUtc(o.aos, true) > currentTime;
        }).map((p) => {
            p.timeUntil = calcTimeUntil(toUtc(p.aos));
            p.gs_name = getGSName(p.gs_id);
            p.band = getGSBand(p.gs_id);
            return p;
        }).slice(0, 10);

        const current = filter(orderedPasses, (o) => {
            const aos = toUtc(o.aos, true);
            const los = toUtc(o.los, true);
            // BW3 ignores 60-second contacts since those are made in the backend when commands are issued immediately from PIGI
            return currentTime > aos && currentTime < los && (los - aos !== 60000 || o.source === "ast-quindar");
        }).map((p) => {
            p.timeUntil = calcTimeUntil(toUtc(p.aos));
            p.gs_name = getGSName(p.gs_id);
            p.band = getGSBand(p.gs_id);
            return p;
        });

        const upcomingAOS = filter(orderedPasses, (o) => {
            const aos = toUtc(o.aos, true);
            return currentTime > aos - 60000 && currentTime < aos - 58500;
        }).map((p) => {
            p.timeUntil = calcTimeUntil(toUtc(p.aos));
            p.gs_name = getGSName(p.gs_id);
            p.band = getGSBand(p.gs_id);
            return p;
        }).slice(0, 10);

        return {
            previous: previous,
            current: current,
            future: future,
            upcomingAOS: upcomingAOS,
        };
    }

    const calcDuration = (aos, los) => {
        const duration = new Date(los.split(".")[0]) - new Date(aos.split(".")[0]);
        const hours = Math.floor(duration / 3600000);
        const minutes = Math.floor((duration - hours * 3600000) / 60000);
        const seconds = Math.floor((duration - hours * 3600000 - minutes * 60000) / 1000);
        return (hours > 0 ? `${hours}h ` : "") + `${minutes}m ${seconds}s`;
    };

    const getGSName = (gsId) => {
        if (!satellites || satellites.length === 0 || !groundstations.current || !(gsId in groundstations.current)) {
            return gsId;
        }
        return groundstations.current[gsId].gs_name;
    };

    const getGSBand = (gsId) => {
        if (!satellites || satellites.length === 0 || !groundstations.current || !(gsId in groundstations.current)) {
            return "N/A";
        }
        const gs = groundstations.current[gsId];

        return gs && gs.telecoms?.length > 0 ? gs.telecoms[0].telecom_name : "N/A";
    };

    const renderTooltip = (props) => {
        let message = "";
        if (props.popper.state) {
            message = props.popper.state.options.msg;
        }

        return (
            <Tooltip style={{color: "red"}} className="my-tooltip" {...props}>
                {message}
            </Tooltip>
        );
    };
    const truncate = (str, len) => {
        const max = len ?? 8;
        if (str.length > max) {
            return str.slice(0, max) + "...";
        } else {
            return str;
        }
    };

    return hasLoaded ? (<div>
        <div className="groundstation-table-container">
            <table className="groundstation-table">
                <thead>
                    <tr>
                        <th>Groundsite</th>
                        {(!isMobile || showAllContacts) && <th>Satellite</th>}
                        {(!isMobile || !showAllContacts) && <th>Band</th>}
                        <th>AOS</th>
                        {!isMobile && <th>AOS TimeStamp</th>}
                        {!isMobile && <th>LOS TimeStamp</th>}
                        <th>Duration</th>
                        <th>Max El</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {previousPass
                        .map((row) => {
                            return (
                                <tr key={row.id} style={prevPassStyle}>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{show: 250, hide: 400}}
                                        overlay={renderTooltip}
                                        popperConfig={{msg: row.gs_name}}
                                    >
                                        <td>
                                            {isMobile
                                                ? truncate(row.gs_name, 9)
                                                : row.gs_name}
                                        </td>
                                    </OverlayTrigger>
                                    {(!isMobile || showAllContacts) && <td>{row.sat_id}</td>}
                                    {(!isMobile || !showAllContacts) && <td>{row.band}</td>}
                                    <td>
                                        {calcTimeUntil(currentTime, toUtc(row.aos))}{" "}
                                        ago
                                    </td>
                                    {!isMobile && <td>{toUtc(row.aos)}</td>}
                                    {!isMobile && <td>{toUtc(row.los)}</td>}
                                    <td>
                                        {calcDuration(
                                            toUtc(row.aos),
                                            toUtc(row.los),
                                        )}
                                    </td>
                                    <td>
                                        {Math.round(row.maximum_el * 100) / 100}{" "}
                                        <sup>o</sup>
                                    </td>
                                    <td>
                                        <img
                                            className={calcEclipse(
                                                satellites[row.satIndex],
                                                row,
                                            )}
                                            height={20}
                                            width={20}
                                        />
                                    </td>
                                </tr>
                            );
                        })
                        .concat(
                            currentPass
                                .map((row) => {
                                    return (
                                        <tr key={row.id} style={inPassStyle}>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{show: 250, hide: 400}}
                                                overlay={renderTooltip}
                                                popperConfig={{
                                                    msg: row.gs_name,
                                                }}
                                            >
                                                <td>
                                                    {isMobile
                                                        ? truncate(
                                                            row.gs_name,
                                                            9,
                                                        )
                                                        : row.gs_name}
                                                </td>
                                            </OverlayTrigger>
                                            {(!isMobile || showAllContacts)
                                            && <td>{row.sat_id}</td>}
                                            {(!isMobile || !showAllContacts) && <td>{row.band}</td>}
                                            <td>{"In Pass"}</td>
                                            {!isMobile && <td>{toUtc(row.aos)}</td>}
                                            {!isMobile && <td>{toUtc(row.los)}</td>}
                                            <td>
                                                {calcTimeUntil(
                                                    toUtc(row.los),
                                                    currentTime,
                                                )}
                                            </td>
                                            <td>
                                                {Math.round(row.maximum_el * 100)
                                                    / 100}{" "}
                                                <sup>o</sup>
                                            </td>
                                            <td>
                                                <img
                                                    className={calcEclipse(
                                                        satellites[row.satIndex],
                                                        row,
                                                    )}
                                                    height={20}
                                                    width={20}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })
                                .concat(
                                    upcomingPasses.map((row) => {
                                        return (
                                            <tr key={row.id}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{show: 250, hide: 400}}
                                                    overlay={renderTooltip}
                                                    popperConfig={{
                                                        msg: row.gs_name,
                                                    }}
                                                >
                                                    <td>
                                                        {isMobile
                                                            ? truncate(
                                                                row.gs_name,
                                                                9,
                                                            )
                                                            : row.gs_name}
                                                    </td>
                                                </OverlayTrigger>
                                                {(!isMobile || showAllContacts)
                                                && <td>{row.sat_id}</td>}
                                                {(!isMobile || !showAllContacts) && <td>{row.band}</td>}
                                                <td>
                                                    {calcTimeUntil(
                                                        toUtc(row.aos),
                                                        currentTime,
                                                    )}
                                                </td>
                                                {!isMobile && (
                                                    <td>{toUtc(row.aos)}</td>
                                                )}
                                                {!isMobile && (
                                                    <td>{toUtc(row.los)}</td>
                                                )}
                                                <td>
                                                    {calcDuration(
                                                        toUtc(row.aos),
                                                        toUtc(row.los),
                                                    )}
                                                </td>
                                                <td>
                                                    {Math.round(
                                                        row.maximum_el * 100,
                                                    ) / 100}{" "}
                                                    <sup>o</sup>
                                                </td>
                                                <td>
                                                    <img
                                                        className={calcEclipse(
                                                            satellites[row.satIndex],
                                                            row,
                                                        )}
                                                        height={20}
                                                        width={20}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    }),
                                )
                                .slice(0, 30),
                        )}
                </tbody>
            </table>
        </div>
        <div className="groundstation-checkbox-container">
            <FormControlLabel
                control={<Checkbox className="gsCheckbox" checked={showAllContacts} color="default"
                    sx={{
                        // "& .MuiSvgIcon-root": {fontSize: "large"},
                        "color": grey[500],
                        "&.Mui-checked": {
                            color: grey[500],
                        },
                    }}
                    onChange={onShowAllContactsCheck} />
                } label="Show contacts for all visible satellites" />
        </div>
    </div>
    ) : null;
});

GroundStationTable.propTypes = {
    popper: PropTypes.object,
};

GroundStationTable.displayName = "GroundStationTable";

export default GroundStationTable;
