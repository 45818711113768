import {useState} from "react";

const useGlobeSettings = () => {
    const [cadModelIndex, setCadModelIndex] = useState(
        parseInt(localStorage.getItem("cadModelIndex") ?? 1),
    );
    const [globeRadius, setGlobeRadiusState] = useState();
    const [propStepSize, setPropStepSizeState] = useState(1000); // ms
    const [pathColorArray, setPathColorArrayState] = useState([
        [
            "rgb(242, 206, 0)",
            "rgb(64, 55, 9)",
        ],
        [
            "rgb(0, 204, 173)",
            "rgb(9, 64, 55)",
        ],
        // [
        //     "rgb(0, 242, 205)",
        //     "rgb(9, 64, 55)",
        // ],
        // [
        //     "rgb(1, 72, 89)",
        //     "rgb(5, 29, 36)",
        // ],
    ]);
    // const [pathColorArray, setPathColorArrayState] = useState(["rgba(0, 177, 0, 0.7)", "rgba(200, 0, 0, 0.7)"])
    const [ringColor, setRingColorState] = useState("rgba(0, 177, 0, 0.7)");
    const [haloData, setHaloDataState] = useState([{}]);

    const [groundstations, setGroundstationsState] = useState([]);
    const [telemCatIndex, setTelemCatIndex] = useState(0);

    const [showFuture, setShowFutureState] = useState(
        localStorage.getItem("showFuture") ?? "Future",
    );
    const [trackSat, setTrackSatState] = useState(
        localStorage.getItem("trackSat")
            ? localStorage.getItem("trackSat") === "true"
            : true,
    );

    const [hiddenSatellites, setHiddenSatellitesState] = useState(
        localStorage.getItem("hiddenSatellites")
            ? JSON.parse(localStorage.getItem("hiddenSatellites"))
            : [],
    );

    const isConstellationShowing = (selectedSatIndex) => {
        for (const index of hiddenSatellites) {
            if (index !== selectedSatIndex || hiddenSatellites.length === 1) {
                return false; // a satellite in the constellation is being hidden
            }
        }
        return true;
    };

    // this doesn't need to persist
    const [showConstellationGrid, setShowConstellationGrid] = useState(null);

    const [showAllContacts, setShowAllContactsState] = useState(
        localStorage.getItem("showAllContacts")
            ? localStorage.getItem("showAllContacts") === "true"
            : true,
    );

    const [showOverpassTable, setShowOverpassTable] = useState(
        localStorage.getItem("showTable") === "true" ?? true,
    );

    const [showAxis, setShowAxisState] = useState(
        localStorage.getItem("showAxis")
            ? localStorage.getItem("showAxis") === "true"
            : true,
    );
    const [showSAA, setShowSAA] = useState(
        localStorage.getItem("showSAA")
            ? localStorage.getItem("showSAA") === "true"
            : true,
    );

    const setGlobeRadius = (ref) => setGlobeRadiusState(ref); // globeEl ref
    const setPropStepSize = (size) => setPropStepSizeState(size);
    const setPathColorArray = (color) => setPathColorArrayState(color); // hex or rgba
    const setRingColor = (color) => setRingColorState(color); // hex or rgba
    const setHaloData = (halos) => setHaloDataState(halos); // array of halo objects

    // Set with fetched data within useRenderGlobe and useRenderSatellites
    const setGroundstations = (data) => setGroundstationsState(data); // Groundstations array

    // Controls (these should be in settings)
    const setShowFuture = (bool) => setShowFutureState(bool); // False will draw orbit line backwards. True, forwards.
    const setTrackSat = (bool) => setTrackSatState(bool); // Camera will track currently selected sat
    const setShowAxis = (bool) => setShowAxisState(bool); // Show Earth and Satellite AXIS
    const setHiddenSatellites = (sats) => {
        localStorage.setItem("hiddenSatellites", JSON.stringify(sats));
        return setHiddenSatellitesState(sats); // which satellites in constellation to not display
    };

    const setShowAllContacts = (bool) => {
        localStorage.setItem("showAllContacts", bool);
        return setShowAllContactsState(bool);
    };

    const [telemCats, setTelemCats] = useState(null);
    const [relSatTelem, setRelSatTelem] = useState(null);

    return {
        // State
        globeRadius,
        propStepSize,
        pathColorArray,
        ringColor,
        haloData,
        groundstations,
        showFuture, // GlobeProvider manages this
        trackSat, // GlobeProvider manages this
        hiddenSatellites,
        showConstellationGrid,
        showOverpassTable,
        showAxis, // GlobeProvider manages this
        showSAA,
        cadModelIndex,
        telemCatIndex,
        telemCats,
        relSatTelem,
        showAllContacts,

        // Setters
        setCadModelIndex,
        setGlobeRadius,
        setPropStepSize,
        setPathColorArray,
        setRingColor,
        setHaloData,
        setGroundstations,
        setShowFuture, // GlobeProvider manages this
        setTrackSat, // GlobeProvider manages this
        setHiddenSatellites,
        isConstellationShowing,
        setShowConstellationGrid,
        setShowOverpassTable,
        setShowAxis, // GlobeProvider manages this
        setShowSAA,
        setTelemCatIndex,
        setTelemCats,
        setRelSatTelem,
        setShowAllContacts,
    };
};

export default useGlobeSettings;
